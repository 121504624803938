import React from 'react';
import './App.css';
import {Otp} from './components';
import {themeOrnamentDefault, ThemeProvider} from "@ornament-ui/kit/ThemeProvider";
import {Users} from "./components/users";


function App() {


  return (
    <div className="App">
      <ThemeProvider theme={themeOrnamentDefault}>
        <Otp />
        {/*<Users />*/}
      </ThemeProvider>

    </div>
  );
}

export default App;
