import React, {useState} from "react";
import {Typography} from "@ornament-ui/kit/Typography";
import {Stack} from "@ornament-ui/kit/Stack";
import {Input} from "@ornament-ui/kit/Input";

export const Form1 = () => {
  // const [ val, setVal] = useState('');

  return <Stack direction="column" gap="m" style={{border: '1px solid orange'}} fullWidth>
    <Typography> FORM 1</Typography>
    <Input type="text" inputMode="numeric" />
  </Stack>;
};
