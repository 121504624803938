import { OTPInput } from "@ornament-ui/kit/OTPInput";
import React, {useEffect, useRef, useState} from "react";
import {Stack} from "@ornament-ui/kit/Stack";
import {Button} from "@ornament-ui/kit/Button";
import {Form1, Form2} from "./components";

export const Otp = () => {

  const [val, setVal] = useState(0);

  useEffect(() => {
    if ('OTPCredential' in window) {
      // setText('TRUE');
      // console.log('asd');
      navigator.credentials.get({
        //@ts-ignore
        otp: {transport:['sms']}
      }).then((otp:any) => {
        console.log(otp);
        // // setText(otp?.id || '');
        // changeHandle(otp?.code || '');
      })
        .catch(e => console.log('ERROR: ', e));
    }


  } , []);
  return <div style={{width: 400}}>
    <Stack direction="column" fullWidth>
      {val === 0 && <p>HALLO</p>}
      {val === 1 && <Form1 />}
      {val === 2 && <Form2 />}
      <Button onClick={() => setVal(0)}>NUM 0</Button>
      <Button onClick={() => setVal(1)}>NUM 1</Button>
      <Button onClick={() => setVal(2)}>NUM 2</Button>
    </Stack>
  </div>;
};

export default Otp;
